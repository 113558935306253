<template>
  <b-modal
    ref="modal-warranty-item-import"
    hide-footer
    :title="titleExcel"
    id="modal-warranty-item-import"
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <b-row>
        <b-col>
          <div class="form-group">
            <span>
              <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
              format, hoặc có thể tải ở
              <a :href="urlExcel">đây</a>
            </span>
          </div>
        </b-col>
      </b-row>
      <span> <b>Bước 2:</b> Tiến hành Import </span>
      <b-row>
        <b-col>
          <UploadFileMulter
            v-on:send-file="getDataFromExcel"
            v-on:clear-file="clearFileExcel"
            :cmdUrl="'warrantyPackages/warranty-item/import-excel/'"
          />
          <span v-if="fileName"
            >Tên file: <b>{{ fileName }}</b>
          </span>
        </b-col>
      </b-row>
      <!-- End input session -->
    </div>
    <div class="mt-10" v-show="importItems.length !== 0">
      <hr
        class="hr-text"
        data-content="Danh sách GBH"
        style="font-weight: 600"
      />
      <b-table
        class="myTable"
        caption-top
        responsive
        bordered
        hover
        :fields="fields"
        :items="importItems"
        id="my-table"
        :per-page="10"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle ml-3"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(productName)="row">
          <p
            v-if="row.item.productCode"
            style="font-size: 12px; font-weight: 500; margin-bottom: 0"
          >
            ( {{ row.item.productCode }} )
          </p>
          <span
            v-text="row.item.productName"
            style="white-space: normal; color: #3699ff"
          ></span>
        </template>

        <template #cell(statusValidate)="data">
          <div v-if="!data.item.onLoading">
            <div class="d-table-cell align-middle" style="width: 10%">
              <i
                v-b-tooltip.hover
                :title="htmlGetStatusValidate(data.item, 'title')"
                :class="htmlGetStatusValidate(data.item, 'class')"
              ></i>
            </div>
            <div class="d-block align-middle pb-1 pl-2" style="width: 90%">
              <div v-for="(items, i) in data.item.errors" :key="i">
                <span class="text-danger">{{ items }}</span>
              </div>
            </div>
            <div class="d-table-cell align-middle pb-1 pl-2" style="width: 90%">
              {{ data.item.statusValidateName }}
            </div>
          </div>
          <div v-else>
            <b-spinner small variant="primary" class="mr-2"></b-spinner>
            <span class="text-primary">Đang xử lí...</span>
          </div>
        </template>
      </b-table>
      <b-col class="row mt-3">
        <b>
          Tổng số dòng:
          {{ importItems ? importItems.length : 0 }}
        </b>
      </b-col>
      <b-pagination
        v-show="importItems.length > 10"
        v-model="currentPage"
        :total-rows="rows()"
        :per-page="10"
        aria-controls="my-table"
        align="right"
      ></b-pagination>
    </div>
    <b-overlay
      v-if="!hasError && importItems.length"
      :show="onLoadingList"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button
        style="width: 80px"
        class="mt-6"
        :disabled="onLoadingList || !importItems.length"
        v-if="importItems.length"
        variant="primary"
        size="sm"
        @click="onSave"
      >
        <strong>Lưu</strong>
      </b-button>
    </b-overlay>

    <b-button
      class="mt-6"
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="hideModalExcel"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>
<script>
import { BASE_URL } from '@/utils/constants';
import { cloneDeep, convertPrice, makeToastFaile } from '@/utils/common';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';

const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  READY: 2,
  LOADING: 3,
};

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  name: 'ImportWarrantyItemModal',
  data() {
    return {
      titleExcel: 'Nhập excel GBH',
      urlExcel:
        BASE_URL + 'media/upload/warranty-item/Temp_Import_WarrantyItem_.xlsx',
      onLoadingList: false,
      currentPage: 1,
      file: null,
      importItems: [],
      isBusy: false,
      fileName: '',
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'discountTypeName',
          label: 'Loại chiết khấu',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center align-middle',
        },
        {
          key: 'discountValue',
          label: 'Giá trị chiết khấu',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'discountPrice',
          label: 'Giá bán chiết khấu',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'fromPrice',
          label: 'Mức giá từ',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'toPrice',
          label: 'Mức giá đến',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'statusValidate',
          label: 'Trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'align-middle text-center',
        },
      ],
    };
  },
  components: {
    UploadFileMulter,
  },
  computed: {
    hasError() {
      const errors = this.importItems.map((item) => item.errors).flat();
      return errors.length > 0;
    },
  },
  methods: {
    convertPrice,
    hideModalExcel() {
      this.$refs['modal-warranty-item-import'].hide();
      this.clearFileExcel();
    },
    onSave: function () {
      const items = cloneDeep(this.importItems);
      const inValidItem = items.find(
        (item) => item.statusValidate === STATUS_VALIDATE.INVALID,
      );
      if (inValidItem) {
        return makeToastFaile('Vui lòng nhập dự liệu hợp lệ!');
      }
      this.hideModalExcel();
      this.$emit('on-receipt-warranty-item', items);
    },
    rows() {
      return this.importItems.length;
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.READY: {
          name =
            type === 'class'
              ? 'fa fa-check-circle text-primary'
              : 'Sẵn sàng tạo mới';
          break;
        }
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-success'
              : 'Sẵn sàng tạo mới';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;
      this.importItems = [...data.rows].map((item) => {
        const statusValidate =
          item.errors && item.errors.length > 0
            ? STATUS_VALIDATE.INVALID
            : STATUS_VALIDATE.READY;
        return {
          ...item,
          onLoading: false,
          statusValidate: statusValidate,
        };
      });
      this.onLoadingList = false;
    },
    clearFileExcel() {
      this.importItems = [];
      this.fileName = '';
      this.file = null;
      this.onLoadingList = false;
    },
  },
};
</script>

<style lang="scss">
.modal-warranty-item-import {
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}
</style>
