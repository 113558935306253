import Vue from 'vue';
// Make common toast notifications.
export const getToastInstance = () => {
  return {
    makeToastSuccess: message => {
      const vm = new Vue();
      vm.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFailure: message => {
      const vm = new Vue();
      vm.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    }
  };
};
