<template>
  <div class="warranty-package-info">
    <KTCodePreview v-bind:title="'Thông tin gói bảo hành '">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="3">
                <b-form-group>
                  <label class="form-group-label">Tên gói bảo hành:</label>
                  <b-form-input
                    size="sm"
                    v-model="mainModel.name"
                    placeholder="Nhập tên"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label class="form-group-label">Mã gói bảo hành:</label>
                  <b-form-input
                    size="sm"
                    v-model="mainModel.code"
                    placeholder="Nhập mã"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label class="form-group-label">Ngày bắt đầu:</label>
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="mainModel.startDate"
                  ></date-picker>
                </b-form-group>

                <b-form-group>
                  <label class="form-group-label">Ngày kết thúc:</label>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="mainModel.endDate"
                  ></date-picker>
                </b-form-group>
              </b-col>

              <b-col cols="5">
                <label class="form-group-label">Mô tả:</label>
                <b-form-textarea
                  v-model="mainModel.description"
                  placeholder="Nhập mô tả"
                  rows="13"
                ></b-form-textarea>
              </b-col>
              <b-col cols="2">
                <label class="form-group-label">Trạng thái:</label>
                <b-form-select
                  size="sm"
                  v-model="mainModel.isActive"
                  :options="listStatus"
                  value-field="id"
                  text-field="name"
                  class="select-style"
                ></b-form-select>
              </b-col>
              <b-col cols="2"></b-col>
            </b-row>
            <b-row>
              <b-form-group class="col-md-12 mt-4 pr-0">
                <label class="form-group-label">Tìm sản phẩm:</label>
                <b-col md="12" class="row pr-0">
                  <b-col md="6" class="p-0">
                    <Autosuggest
                      :model="warrantyPackageItemName"
                      :suggestions="filteredOptionsProduct"
                      placeholder="tên, mã, mã vạch sản phẩm"
                      :limit="10"
                      @select="onSelectedWarrantyPackageItem"
                      @change="onInputChangeWarrantyPackageItem"
                      :disabled="false"
                      suggestionName="productName"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      v-model="selectTypeSearch"
                      :options="listTypeSearch"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      class="select-style"
                      @change="debounceInput"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      v-model="selectTypeOfProduct"
                      :options="listTypeOfProduct"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      class="select-style"
                      @change="debounceInput"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="pr-0">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="secondary"
                        size="sm"
                        @click="showModalImportWarrantyItem"
                      >
                        <i style="font-size: 1rem" class="far fa-file-excel"></i
                        >Nhập SP từ excel
                      </b-button>
                    </div>
                  </b-col>
                </b-col>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <div>
                  <b-table
                    bordered
                    hover
                    :fields="fieldProduct"
                    :items="mainModel.warrantyPackageItems"
                    v-if="renderComponent"
                    :per-page="10"
                    :current-page="currentPageTable"
                    id="my-table-product"
                  >
                    <template v-slot:cell(productName)="row">
                      <p v-if="row.item.productCode" class="product-code-row">
                        ( {{ row.item.productCode }} )
                      </p>
                      <span
                        v-text="row.item.productName"
                        class="product-name-row"
                      ></span>
                    </template>
                    <template v-slot:cell(discount)="row">
                      <b-input-group>
                        <template #prepend>
                          <b-form-select
                            v-model="row.item.discountType"
                            :options="discountOptions"
                            size="sm"
                            value-field="id"
                            text-field="name"
                          >
                          </b-form-select>
                        </template>
                        <b-form-input
                          class="input-style text-right"
                          size="sm"
                          type="text"
                          v-model="row.item.discountValue"
                          placeholder="Chiết khấu"
                          v-mask="mask"
                          @input="handleInputDiscount(row.item)"
                        ></b-form-input>
                      </b-input-group>
                    </template>
                    <template v-slot:cell(fromPrice)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="row.item.fromPrice"
                        v-mask="mask"
                        @change="forceRerender"
                      ></b-input>
                    </template>
                    <template v-slot:cell(toPrice)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="row.item.toPrice"
                        v-mask="mask"
                        @change="forceRerender"
                      ></b-input>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <v-icon
                        small
                        class="text-danger"
                        @click="deleteItemInArray(row.item.productId)"
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </b-table>
                  <b-pagination
                    v-show="mainModel.warrantyPackageItems.length > 10"
                    v-model="currentPageTable"
                    :total-rows="rowsTable"
                    :per-page="10"
                    aria-controls="my-table-product"
                    align="right"
                  ></b-pagination>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>

            <div class="separator separator-dashed my-5"></div>

            <div>
              <p class="text-dark font-size-h5 font-weight-bold">
                Gói bảo hành cấu hình cho
              </p>
            </div>

            <b-row>
              <b-col md-4>
                <b-form-group>
                  <label class="form-group-label">Danh mục</label>
                  <vue-multi-select
                    style="margin-bottom: 2rem"
                    v-model="mainModel.applyCategories"
                    :options="categories"
                    placeholder="Chọn danh mục"
                    option-label="name"
                  />
                </b-form-group>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row>
              <b-form-group class="col-md-12 pr-0">
                <label class="form-group-label">Tìm sản phẩm:</label>
                <b-col md="12" class="row pr-0">
                  <b-col md="6" class="p-0">
                    <Autosuggest
                      :model="applyProductName"
                      :suggestions="filteredOptionsProductFor"
                      placeholder="tên, mã, mã vạch sản phẩm"
                      :limit="10"
                      @select="onSelectedApplyProduct"
                      @change="onInputChangeApplyProduct"
                      :disabled="false"
                      suggestionName="productName"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      v-model="selectTypeSearchFor"
                      :options="listTypeSearchFor"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      class="select-style"
                      @change="debounceInputApplyItem"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      v-model="selectTypeOfProductFor"
                      :options="listTypeOfProduct"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      class="select-style"
                      @change="debounceInputApplyItem"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="pr-0">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="secondary"
                        size="sm"
                        @click="showModalImportWarrantyApplyItem"
                      >
                        <i style="font-size: 1rem" class="far fa-file-excel"></i
                        >Nhập SP từ excel
                      </b-button>
                    </div>
                  </b-col>
                </b-col>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col cols="12" md="10">
                <div>
                  <b-table
                    bordered
                    hover
                    :fields="fields"
                    :items="mainModel.applyProducts"
                    :per-page="10"
                    :current-page="currentPageMain"
                    id="my-table-main"
                  >
                    <template v-slot:cell(actions)="row">
                      <v-icon
                        small
                        class="text-danger"
                        @click="showDeleteAlert(row.item)"
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </b-table>
                  <b-pagination
                    v-show="mainModel.applyProducts.length > 10"
                    v-model="currentPageMain"
                    :total-rows="rowsMain"
                    :per-page="10"
                    aria-controls="my-table-main"
                    align="right"
                  ></b-pagination>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              v-if="checkPermission('WARRANTY_UPDATE')"
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <b-button
              v-if="checkPermission('WARRANTY_UPDATE')"
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onback"
              >Hủy</b-button
            >
            <b-button
              v-if="!checkPermission('WARRANTY_UPDATE')"
              style="font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onback"
              >Hủy</b-button
            >
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
        <ImportWarrantyItemModal
          v-on:on-receipt-warranty-item="onReceiptWarrantyItem"
        />
        <ImportWarrantyApplyItemModal
          v-on:on-receipt-warranty-apply-item="onReceiptWarrantyApplyItem"
        />
      </template>
    </KTCodePreview>
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import VueMultiSelect from 'vue-simple-multi-select';
import _ from 'lodash';

import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { checkPermission } from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { DP_CONFIG } from '@/utils/date';
import {
  calculateRemainingMoney,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  currencyMask,
  unMaskPrice,
} from '@/utils/common';
import ImportWarrantyItemModal from '../../components/warranty-package/ImportWarrantyItemModal';
import ImportWarrantyApplyItemModal from '../../components/warranty-package/ImportWarrantyApplyItemModal';

export default {
  data() {
    return {
      dpConfigs: DP_CONFIG,
      fields: [
        {
          key: 'productCode',
          label: 'Mã',
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-left',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        { key: 'actions', label: '' },
      ],
      filteredOptionsProductFor: [],
      optionsProductFor: [],
      filteredOptionsProduct: [],
      optionsProduct: [],
      warrantyPackageItemName: '',
      applyProductName: '',
      categories: [],
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Đã khóa',
        },
      ],
      isSearching: false,
      listTypeSearch: [
        {
          id: 3,
          name: 'Sản phẩm dịch vụ',
        },
      ],
      listTypeSearchFor: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      selectTypeSearch: 3,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      currentPage: 1,
      currentPageFor: 1,
      currentPageMain: 1,
      currentPageTable: 1,
      fieldProduct: [
        {
          key: 'productName',
          label: 'Tên SP',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'discount',
          label: 'Chiết khấu',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountPrice',
          label: 'Giá bán CK',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'fromPrice',
          label: 'Mức giá bán từ',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'toPrice',
          label: 'Mức giá bán đến',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      renderComponent: true,
      mask: currencyMask,
      mainModel: {
        id: null,
        name: '',
        code: '',
        description: '',
        startDate: '',
        endDate: '',
        isActive: true,
        warrantyPackageItems: [],
        applyCategories: [],
        applyProducts: [],
      },
      discountOptions: [
        {
          id: 1,
          name: '$',
        },
        {
          id: 2,
          name: '%',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    VueMultiSelect,
    Autosuggest,
    ImportWarrantyItemModal,
    ImportWarrantyApplyItemModal,
  },
  mounted() {
    this.mainModel.id = this.$route.query.id;
    if (this.mainModel.id) {
      this.getInfoById();
    }
    const titleName = this.checkPermission('WARRANTY_UPDATE')
      ? 'Cập nhật gói bảo hành '
      : 'Chi tiết gói bảo hành';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Gói bảo hành', route: '/warranty-packages' },
      {
        title: titleName,
      },
    ]);
  },
  computed: {
    rowsTable() {
      return this.mainModel.warrantyPackageItems.length;
    },
    rowsMain() {
      return this.mainModel.applyProducts.length;
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    getCategories() {
      ApiService.query('category')
        .then(({ data }) => {
          if (data.status === 1) {
            this.categories = data.data.list_caterogy;
          } else {
            makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    getInfoById: async function () {
      const enpoint = cmdUrl.WarrantyPackageUrl.byId.replace(
        '{id}',
        this.mainModel.id,
      );
      await ApiService.get(enpoint).then((res) => {
        const {
          name,
          code,
          description,
          startDate,
          endDate,
          products: warrantyPackageItems,
          appliedProducts: applyProducts,
          appliedCategories: categories,
          isActive,
        } = res.data.data;
        this.mainModel.name = name;
        this.mainModel.code = code;
        this.mainModel.description = description;
        this.mainModel.startDate = startDate
          ? moment(startDate).format('DD/MM/YYYY')
          : null;
        this.mainModel.endDate = endDate
          ? moment(endDate).format('DD/MM/YYYY')
          : null;
        this.mainModel.warrantyPackageItems = warrantyPackageItems.map(
          (warrantyPackageItem) => {
            this.handleInputDiscount(warrantyPackageItem);
            return {
              ...warrantyPackageItem,
            };
          },
        );
        this.mainModel.applyProducts = applyProducts.map((applyProduct) => {
          return {
            productId: applyProduct.id,
            productName: applyProduct.productName,
            productCode: applyProduct.productCode,
            sellingPrice: applyProduct.sellingPrice,
            deleted: 0,
          };
        });
        this.mainModel.applyCategories = categories;
        this.mainModel.isActive = isActive;
      });
    },
    showDeleteAlert: async function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then(async (result) => {
        if (result.value) {
          this.mainModel.applyProducts = _.reject(
            this.mainModel.applyProducts,
            item,
          );
        }
      });
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.mainModel.warrantyPackageItems.length; i++) {
        if (this.mainModel.warrantyPackageItems[i].productId === productId) {
          this.mainModel.warrantyPackageItems.splice(i, 1);
        }
      }
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.optionsProduct = [];
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`,
      ).then(({ data }) => {
        const products = data.data;
        this.optionsProduct = products;
        this.filteredOptionsProduct = [...this.optionsProduct];
        this.isSearching = false;
        if (textSearch !== this.warrantyPackageItemName) {
          let nextTextSearch = this.warrantyPackageItemName;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.optionsProductFor = [];
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearchFor}&typeOfProduct=${this.selectTypeOfProductFor}`,
      ).then(({ data }) => {
        const products = data.data;
        this.optionsProductFor = products;
        this.filteredOptionsProductFor = [...this.optionsProductFor];
        this.isSearching = false;
        if (textSearch !== this.applyProductName) {
          let nextTextSearch = this.applyProductName;
          this.fetchProductFor(nextTextSearch);
        }
      });
    },
    onSelectedWarrantyPackageItem(option) {
      this.warrantyPackageItemName = '';

      const warrantyPackageItem = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        sellingPrice: option.item.sellingPrice,
        discountType: 1,
        discountValue: 0,
        discountPrice: 0,
        deleted: 0,
        fromPrice: 0,
        toPrice: 0,
      };

      this.mainModel.warrantyPackageItems.push(warrantyPackageItem);
    },
    onInputChangeWarrantyPackageItem(text) {
      if (!text) {
        text = '';
      }
      this.warrantyPackageItemName = text;

      this.debounceInput();
    },
    onSelectedApplyProduct(option) {
      this.applyProductName = '';

      const applyProduct = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        sellingPrice: option.item.sellingPrice,
        deleted: 0,
      };

      this.mainModel.applyProducts.push(applyProduct);
    },
    onInputChangeApplyProduct(text) {
      this.applyProductName = text;

      this.debounceInputApplyItem();
    },
    onback: function () {
      history.back();
    },
    onSubmit: async function () {
      this.mainModel.warrantyPackageItems.forEach((element) => {
        element.discountValue = element.discountValue
          ? unMaskPrice(element.discountValue)
          : 0;
        element.fromPrice = element.fromPrice
          ? unMaskPrice(element.fromPrice)
          : 0;
        element.toPrice = element.toPrice ? unMaskPrice(element.toPrice) : 0;
      });
      const payload = {
        id: this.mainModel.id,
        name: this.mainModel.name,
        code: this.mainModel.code,
        description: this.mainModel.description,
        products: this.mainModel.warrantyPackageItems,
        appliedCategories: this.mainModel.applyCategories,
        appliedProducts: this.mainModel.applyProducts,
        isActive: this.mainModel.isActive,
        startDate: this.mainModel.startDate
          ? moment(this.mainModel.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        endDate: this.mainModel.endDate
          ? moment(this.mainModel.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
      };
      // convert import price.
      _.map(payload.products, (item) => {
        item.repurchasePrice = item.importPrice;
      });
      if (!payload.id) {
        const endPoint = cmdUrl.WarrantyPackageUrl.root;
        this.callApiUpsert(payload, 'post', endPoint);
      } else {
        const endPoint = cmdUrl.WarrantyPackageUrl.byId.replace(
          '{id}',
          payload.id,
        );
        this.callApiUpsert(payload, 'patch', endPoint);
      }
    },
    callApiUpsert(payload, method, cmdUrl) {
      ApiService[method](cmdUrl, payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.warrantyPackageItemName;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    debounceInputApplyItem: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.applyProductName;
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    showModalImportWarrantyItem() {
      this.$bvModal.show('modal-warranty-item-import');
    },
    showModalImportWarrantyApplyItem() {
      this.$bvModal.show('modal-warranty-apply-item-import');
    },
    checkPermission(condition) {
      return checkPermission(condition);
    },
    handleInputDiscount(payload) {
      if (!payload) return;

      const price = calculateRemainingMoney(
        payload.discountType,
        Number(unMaskPrice(payload.discountValue)),
        payload.sellingPrice,
      );

      payload.discountPrice = Number(unMaskPrice(price));
    },
    onReceiptWarrantyItem(warrantyItems) {
      const receiptProductIds = warrantyItems.map(
        (warrantyItem) => warrantyItem.productId,
      );
      const existedProducts = this.mainModel.warrantyPackageItems.filter(
        (warrantyPackageItem) =>
          receiptProductIds.includes(warrantyPackageItem.productId),
      );
      if (existedProducts.length) {
        const existedProductNames = existedProducts
          .map((existedProduct) => existedProduct.productName)
          .join(', ');
        return makeToastFaile(`Sản phẩm ${existedProductNames} đã tồn tại!`);
      }
      this.mainModel.warrantyPackageItems =
        this.mainModel.warrantyPackageItems.concat(warrantyItems);
    },
    onReceiptWarrantyApplyItem(applyItems) {
      const receiptProductIds = applyItems.map(
        (warrantyItem) => warrantyItem.productId,
      );
      const existedProducts = this.mainModel.applyProducts.filter(
        (applyProduct) => receiptProductIds.includes(applyProduct.productId),
      );
      if (existedProducts.length) {
        const existedProductNames = existedProducts
          .map((existedProduct) => existedProduct.productName)
          .join(', ');
        return makeToastFaile(`Sản phẩm ${existedProductNames} đã tồn tại!`);
      }
      this.mainModel.applyProducts =
        this.mainModel.applyProducts.concat(applyItems);
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<style lang="scss" scoped>
.warranty-package-info {
  .form-group-label {
    font-weight: 600;
  }
  .product-code-row {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .product-name-row {
    white-space: normal;
  }
}
</style>
